<template>
  <div
    v-cloak
    id="purchase_order_print"
    style="background-color: white;font-size: 22px;font-weight: 700;color: black;"
  >
    <div
      class=""
      style="display: flex"
    >
      <div class="col-6">
        <!--        <img src="">-->
        <!--                {{asset('e-shop/img/logo.jpg')}}-->
        <img
          src="@/assets/images/logo.png"
          class="img-print-invoice"
          style="height: 100px;"
        >
      </div>
      <div
        class="col-md-6"
      >
        <div
          class=""
          style="text-align: right"
        >
          <div style="text-align: right;line-height: 15px;">
            <div style="font-size: 8px;color: #7a7a7a">
              บริษัท วอท ยู วอนท์ 59 จํากัด (สํานักงานใหญ่)
            </div>
            <div style="font-size: 8px;color: #7a7a7a;margin-top: -5px;">
              7/272 หมู่5 ตำบล บางใหญ่ อำเภอบางใหญ่ จังหวัดนนทบุรี  11140
            </div>
          </div>
        </div>
        <br>
        <table style="width: 100%;border-spacing: 0;border-collapse: collapse;margin-top: -30px;">
          <thead>
            <tr>
              <th style="width: 60%; background-color: black; border-radius: 15px 0 0;">
                <div style="padding: 10px;">
                  <center v-if="purchase_order_type === 1 || purchase_order_type === '1'">
                    <span style="font-size: 20px; color: white; font-weight: 700;">Purchase Order</span>
                    <br>
                    <span style="color: white;font-weight: 700;padding: 5px">ใบสั่งซื้อ</span> <br>
                  </center>
                  <center v-if="purchase_order_type === 2 || purchase_order_type === '2'">
                    <span style="font-size: 20px;color: white;font-weight: 700;padding: 5px">Purchase Data Entry</span>
                    <br>
                    <span style="color: white; font-weight: 700; padding: 5px;">ใบรับสินค้า</span> <br>
                  </center>
                </div>
              </th>
              <th style="width: 40%; border: 1px solid black;padding: 0">
                <center>
                  <span style="font-size: 14px;"> ต้นฉบับ/Original </span> <br>
                  <hr style="border: 1px solid black;">
                  <br>
                  <p style="font-size: 15px;">
                    {{ data.purchase_on }}
                  </p>
                </center>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div style="display: flex;margin-top: -15px;padding:0;">
      <div class="col-md-12">
        <table class="table-text-supplier">
          <thead>
            <tr>
              <th style="width: 50%">
                <div>ผู้ขาย: {{ data.supplier_name }}</div>
                <div style="font-size: 14px">
                  Supplier
                </div><br>
                <div>เลขที่ผู้เสียภาษี : {{ data.tax_id }}</div>
                <div style="font-size: 14px">
                  Tax ID
                </div><br>
                <div>ที่อยู่ : </div><br>
                <div style="font-size: 14px">
                  Address
                </div>
              &nbsp; &nbsp; <div>
{{ data.address }}
                                <br>
                                </div>
              </th>
              <th style="width: 25%;">
                <div>
                  <div>วันที่: {{ data.date }}</div>
                  <div style="font-size: 14px">
                    Issue Date
                  </div><br>
                  <div>การชำระเงิน: {{ data.payment_type ? data.payment_type.name : '-' }}</div><br>
                  <div style="font-size: 14px">
                    Credit Term
                  </div><br>
                  <div>ผู้ติดต่อ: {{ data.contact_name }}</div>
                  <div style="font-size: 14px">
                    Contact Name
                  </div><br>
                  <div>ชื่อโปรเจค: {{ data.project_name }}</div>
                  <div style="font-size: 11px">
                    Project Name
                  </div><br>
                </div>
              </th>
              <th style="width: 25%;">
                <div>จัดทำโดย: {{ data.employee ? data.employee.employee_name : '-' }}</div>
                <div style="font-size: 14px">
                  PrePared By
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <div
      class="col-12"
      style="margin-top:-15px;padding-left: 13px;padding-right: 13px;"
    >
      <table
        class="table-content"
        style="width: 100%;border-spacing: 0;border-collapse: collapse;"
      >
        <thead
          style="background: black; color: white;border-radius: 50%;"
        >
          <tr>
            <th style="text-align: center; padding: 5px;font-size: 16px;width: 8%">
              เลขที่<br>No.
            </th>
            <th
              colspan="2"
              style="text-align: center;padding: 10px"
            >
              รายการ <br>Descriptions
            </th>
            <th style="text-align: center;padding: 5px">
              จำนวน <br>Quantity
            </th>
            <th style="text-align: center;padding: 5px">
              ราคา/หน่วย <br>Unit Price
            </th>
            <th style="text-align: center;padding: 5px">
              ส่วนลด <br>Discount
            </th>
            <th style="text-align: center;padding: 5px">
              จำนวนเงิน (THB) <br> Amount
            </th>
          </tr>
        </thead>
        <tbody class="product_list">
          <tr v-for="(list,index) in data.purchase_order_details">
            <td style="text-align: center;">
              <b>
                {{ index + 1 }}</b>
            </td>
            <td
              colspan="2"
              style="text-align: left;"
            >
              <b>{{ list.product ? list.product.product_name : '-' }}</b>
            </td>
            <td
              style="width: 10%;text-align: center;"
            >
              <b>{{ parseInt(list.amount) }}</b>
            </td>
            <td>
              <b>
                <span>{{ list.price ? list.price : '-' }}</span></b>
            </td>
            <td
              style="width: 10%;"
            >
              <b> <span style="text-align: right">{{ list.discount }}</span></b>
            </td>
            <td style="text-align: right">
              <b><span> {{
                parseFloat((list.price ? list.price : 0) * list.amount).toFixed(2)
              }}</span></b>
            </td>
          </tr>
          <tr
            v-for="index in (5-length_for)"
            v-if="purchase_order_details.length < 5"
          >
            <td style="text-align: center;">
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td
              colspan="2"
              style="text-align: center;"
            >
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td
              style="width: 10%;text-align: center;"
            >
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td style="border-right-style: solid;">
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td style="width: 10%;">
              <p
                style="color: white"
              >
                -
              </p>
            </td>
            <td style="width: 10%;">
              <p style="color: white">
                -
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="col-12"
      style="margin-top: -15px;"
    >
      <div style="display: flex">
        <table
          style="width: 50%;border-spacing: 0;border-collapse: collapse;border-color: transparent"
        >
          <thead>
            <tr class="table-text-amount">
              <th style="border-color: transparent">
                <div style="">
                  จำนวนเงิน
                </div>
                <div
                  style="font-size: 11px;padding: 5px"
                >
                  Amount
                </div>
              </th>
              <th style="border-color: transparent">
                <div style="">
                  {{ data.BAHT_text }}
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <table style="width: 30%;border-spacing: 0;border-collapse: collapse;">
          <thead>
            <tr>
              <th class="table-text">
                <div>รวมเป็นเงินทั้งสิ้น</div>
                <div style="font-size: 11px">
                  subTotal
                </div>
              </th>
            </tr>
            <tr>
              <th class="table-text">
                <div>หักส่วนลดพิเศษ</div>
                <div style="font-size: 11px">
                  Special discount
                </div>
              </th>
            </tr>
            <tr>
              <th class="table-text">
                <div>ยอดรวมหลังหักส่วนลด</div>
                <div style="font-size: 11px">
                  After discount
                </div>
              </th>
            </tr>
            <tr style="text-align: left">
              <th class="table-text">
                <div> จำนวนภาษีมูลค่าเพิ่ม 7 %</div>
                <div style="font-size: 11px">
                  Value Added Tax
                </div>
              </th>
            </tr>
            <tr style="text-align: left">
              <th class="table-text">
                <div>จำนวนเงินรวมทั้งสิ้น</div>
                <div style="font-size: 11px">
                  Total
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <table style="width: 20%;border-spacing: 0;border-collapse: collapse;">
          <thead>
            <tr>
              <th class="table-total">
                {{ data.sub_total }}
              </th>
            </tr>
            <tr style="text-align: right">
              <th class="table-total">
                <p>
                  {{ data.discount }}
                </p>
              </th>
            </tr>
            <tr style="text-align: right">
              <th class="table-total">
                <p>
                  {{ data.sub_total - data.discount }}
                </p>
              </th>
            </tr>
            <tr style="text-align: right">
              <th class="table-total">
                <p v-if="data.is_vat">
                  {{ data.vat }}
                </p>
                <p v-if="!data.is_vat">
                  0.00
                </p>
              </th>
            </tr>
            <tr style="text-align: right;background-color: black;">
              <th
                class="table-total"
                style="color: white"
              >
                <p>
                  {{ data.total }}
                </p>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div>
      <table class="table-content">
        <thead>
          <tr style="text-align: center;font-size: 13px;">
            <th style=" border: 1px solid black;">
              <br>
              <p>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</p>
              <p>ผู้ตรวจสอบ / Approver</p>
              <p>วันที่ / Date _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
            </th>
            <th style=" border: 1px solid black;">
              <br>
              <p style="color: white">
                _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
              </p>
            </th>
            <th style=" border: 1px solid black;">
              <p>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
              <p>ผู้มีอำอาจลงนาม / Authorized Signature </p>
              <p>วันที่ / Date _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
            </th>
          </tr>
        </thead>
      </table>
      <table
        style="width: 100%;background-color: black;border-radius:  0  0  15px 15px;color: white;height: 25px !important;"
      >
        <tr style="padding: 0">
          <th style="text-align: center;padding: 0">
            <div
              style="font-size: 9px;"
            >
              บริษัท วอท ยู วอนท์ 59 จํากัด (สํานักงานใหญ)
            </div>
            <div style="font-size: 9px;margin-top: -5px;">
              7/272 หมู่5 ตำบล บางใหญ่ อำเภอบางใหญ่
              จังหวัดนนทบุรี 11140
            </div>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'

// import config from '../../../config'
// import instance from '../../../axios.service'
import Swal from 'sweetalert2'
import { email, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import instance_purchase_order from '@/services/purchaseOrder'
import instance_payment_type from '@/services/payment'
import '@/assets/style/report.css'

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    type: true ? 'ใบสั่งซื้อสินค้า Purchase Order' : 'ใบสั่งซื้อสินค้า Purchase Order',
    payment_types: [],
    data: {
      supplier_name: '',
      employee_name: '',
      employee_id: '',
      purchase_on: '',
      tax_id: '',
      address: '',
      date: '',
      payment_id: 'เลือกการชำระเงิน',
      contact_name: '',
      project_name: '',
      sub_total: 0,
      discount: 0,
      total: 0,
      vat: 0,
      note: '',
      is_vat: true,
    },
    purchase_order_details: [],
    amount_options: [
      1, 5, 10, 20, 50, 100, 500, 1000,
    ],
    amount: 1,
    selected: [],
    product_options: [],
    count: 0,
    product_select: 'ค้นหาสินค้า',
    purchase_order_type: 1, // ใบสั่งซื้อสินค้า Purchase Order 1    //
    length_for: 0,
    purchase_order_details_length: 7,
  }),
  validations() {
    return {
      editedItem: {
        name: { required },
        value: { required },
      },
    }
  },
  computed: {
    selectAll: {
      get() {
        return this.purchase_order_details ? this.selected.length == this.purchase_order_details.length : false
      },
      set(value) {
        const selected = []

        if (value) {
          Object.entries(this.purchase_order_details).forEach(([key, val]) => {
            if (val.product_id) {
              selected.push(parseInt(val.product_id))
            }
          })
        }

        this.selected = selected
      },
    },
    subTotal() {
      const total = []
      Object.entries(this.purchase_order_details).forEach(([key, val]) => {
        let sum = 0
        if (val.product_id) {
          const { product_costs } = val
          const { amount } = val
          const discount = parseInt(val.discount)
          if ((product_costs * amount) > discount) {
            sum = (product_costs * amount) - discount
          } else {
            sum = (product_costs * amount)
          }
        }

        total.push(parseFloat(sum).toFixed(2))
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    afterDiscount() {
      const { subTotal } = this
      const { discount } = this.data

      return parseFloat(subTotal - discount).toFixed(2)
    },
    VatTotal() {
      const { afterDiscount } = this
      const vat = (afterDiscount * 100) / 107

      return parseFloat(afterDiscount - vat).toFixed(2)
    },
  },

  watch: {},

  created() {

  },
  mounted() {
    this.data.id = this.$route.params.id
    this.purchase_order_type = this.$route.query.purchase_order_type

    this.showPurchaseOrder(this.data.id, this.purchase_order_type)

    // this.payment();
    // this.amountLessThan20();
    // if (!this.data.id) {
    //   this.data.employee_name = '{{auth() ? auth()->user()->user()->employee_name : ''}}'
    // }
    // $('#date').datepicker({
    //   format: 'yyyy-mm-dd',
    // });
  },
  methods: {
    showPurchaseOrder() {
      this.$store.state.isLoading = true
      instance_purchase_order.show(this.data.id, this.purchase_order_type).then(res => {
        this.data = res.data.purchase_orders
        this.data.purchase_order_details = res.data.purchase_order_details
        this.length_for = this.data.purchase_order_details.length
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    amountLessThan20() {
      const data = {
        id: this.data_id,
        amount: this.amount,
        type: this.amount,
        purchase_order_type: this.purchase_order_type,
      }
      instance_purchase_order.show(data.id).then(res => {
        if (res && res.data) {
          this.data = res.data.data
          if (this.data.is_vat === 'Y') {
            this.data.is_vat = true
          } else {
            this.data.is_vat = false
          }
          this.purchase_order_details = res.data.purchase_order_details

          this.data.purchase_order_details = res.data.purchase_order_details
          if (this.data.purchase_order_details.length < 7) {
            this.length_for = (7 - this.data.purchase_order_details.length)
            this.purchase_order_details_length = this.data.purchase_order_details.length
          }
        }
      }).catch(err => {

      })

      // axios.get('{{url('admin/get_purchase_order').'/'.$data->id}}'+ '?amount=' + this.amount + '&type=' + this.type + '&purchase_order_type='+ this.purchase_order_type)
    },
  },
  payment() {
    instance_payment_type.get().then(res => {
      if (res && res.data) {
        this.payment_types = res.data
      }
    })
  },
}
</script>

<style scoped>

br {
  margin: 0 !important;
  padding: 0;
  line-height: 6px;
}

@page {
  size: A4;
  margin: 0;
  background-color: white;
}

table {
  font-family: "Garuda";
  width: 100%;
  font-size: 11pt;
}

.table-text-supplier {
  border: 1px solid black;
  border-spacing: 0;
  line-height: 19px;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: black;
  padding: 2px;
  width: 100%;

}

.table-text-supplier tr{
  padding: 2px !important;

}
.table-text-supplier tr th{
  /*padding: 2px !important;*/

}

.table-text-supplier tr td{
  /*padding: 15px !important;*/

}

table th {
  padding: 2px;
}

html, body {
  line-height: 6px !important;
  margin: 0;
  font-size: 11px;
  background-color: white !important;
  width: 100%;
  padding: 0;
  height: 100vh;
  font-family: "Garuda";
}

#purchase_order_print {
  background-color: white !important;
  padding: 0;
  margin: 0;
}

footer {
  display: none;
}

header {
  display: none;
}

.app-header .navbar {
  display: none;
}

.app-footer {
  display: none;
}

[v-cloak] > * {
  display: none
}

label {
  font-size: 15px;
  font-weight: 700;
}

.table th {
  font-size: 15px;
  font-weight: 700;
}

.table-text {
  font-size: 17px;
  font-weight: 700;
  text-align: left;;
  padding: 5px;
  color: black;
}

.table-total {
  font-size: 18px;
  font-weight: 700;
  text-align: right;
  border: 1px solid black;
  padding: 5px;
  color: black;
}

.product_list tr td {
  border-right: 1px solid black;
}

.table-text-amount {
  background-color: #80808024;
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.table-text-supplier {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: black;
  border-spacing: 0;
  border-collapse: collapse;
}

.table-text-supplier th {
  border: 1px solid black;
}

.table-content {
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid black;
}

.table-content td {
  color: #4A4E5A;
}

.table-content th {
  border: 1px solid black;
}

img {
  width: 30%;
}

.table-text-supplier span, p {
  padding: 2px;
}

#purchase_add {
  /*padding: 5%;*/
}

@media print {
  html, body {
    background-color: white;
    padding: 0;
    margin: 0;
    line-height: 15px;
  }

  p {
    line-height: 15px;
  }

  .img-print_envelope {
    width: 100px;
    height: 100px;
  }

  .table-text-supplier {
    border: 1px solid black;
    border-spacing: 0;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    color: black;
    line-height: 19px;

  }

  .table-text-supplier span {
    padding: 0;
    border-spacing: 0;
    line-height: 19px;
  }

  .table-text-supplier span, p {
    padding: 2px;
    border-spacing: 0;
    line-height: 19px;
  }

  /*.table-striped > tbody > tr:nth-child(2n+1)  {*/
  /*    background-color: #f2f2f2*/
  /*}*/
  .table-striped tbody > tr:nth-child(2n+1) {
    background-color: #b7b4b1;
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-lg-12 {
    width: 100%;
  }

  .table th {
    /*padding: 1.2em !important;*/
    vertical-align: top;
    border-top: 1px solid #f4f5f8;
  }

  .table td {
    /*padding: 15px !important;*/
    vertical-align: top;
    border-top: 1px solid #f4f5f8;
  }

  .table th {
    background-color: rgba(232, 234, 244, 0.6);
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background: none;
  }

  .table-striped tbody tr:nth-of-type(2n) {
    background-color: rgba(232, 234, 244, 0.3)
  }

  table.dataTable.no-footer {
    border-bottom: unset !important;
  }

  .dataTables_filter {
    display: none;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .title {
    /* บีคอนส์ */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    color: #3D438D;
  }

  .marker {
    top: -0.9em !important;
  }

  .m-checkbox.m-checkbox--brand.m-checkbox--solid > input:checked ~ span {
    background: #2e6da4 !important;
  }

  .dataTables_wrapper .pagination .page-item:hover > .page-link {
    background: #2e6da4 !important;
  }

  #removeChechBox {
    cursor: pointer;
  }

  .check_beacon {
    pointer-events: none
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    border: 1px solid transparent !important;
    border-radius: 18px !important;
    background: #2e6da4 !important;
    color: #282a3c !important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background-color: #2e6da4 !important;
    color: white !important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: white !important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    border: 1px solid transparent !important;
    border-radius: 18px !important;
    color: #282a3c !important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    border: 1px solid transparent !important;
    border-radius: 18px !important;
    color: #fff !important;
    background-color: #0a6aa1 !important;
    background: -webkit-linear-gradient(top, #2e6da4 0%, #2e6da4 100%) !important;
  }

  #eventTable .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: white !important;
  }

  #eventTable .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    color: #fff !important;
  }

  #filter_bar {
    padding: 20px;
    height: auto;
  }

  @media (min-width: 426px) {
    #filter_bar {
      position: fixed;
      top: 107px;
      left: 0;
      right: 0;
      z-index: 10;
    }

    #main-content {
      padding-top: 90px;
    }
  }

  @media (min-width: 1025px) {
    #filter_bar {
      left: 255px;
      top: 70px;
    }
  }

  .dataTables_wrapper .dataTable td .m-checkbox, .dataTables_wrapper .dataTable th .m-checkbox {
    left: 7px;
  }

  /* ... the rest of the rules ... */
}

</style>
